// ContextStore.tsx

import React, { createContext, useContext } from 'react';
import { StakeholderData, CompanyData } from './types';

interface TechScoutingContextType {
  techResponse: any;
  setTechResponse: React.Dispatch<React.SetStateAction<any>>;
  selectedCompany: CompanyData | null;
  setSelectedCompany: React.Dispatch<React.SetStateAction<CompanyData | null>>;
  selectedCompanyOrigin: string | null;
  setSelectedCompanyOrigin: React.Dispatch<React.SetStateAction<string | null>>;
  skipFirstPage: number | undefined;
  setSkipFirstPage: React.Dispatch<React.SetStateAction<number|undefined>>;
}

interface StakeholderIdentificationContextType {
  stakeholderResponse: StakeholderData[];
  setStakeholderResponse: React.Dispatch<React.SetStateAction<StakeholderData[]>>;
}

interface ProjectContextType {
  defaultProjectId: string;
  setDefaultProjectId: React.Dispatch<React.SetStateAction<string>>;
  defaultProjectName: string;
  setDefaultProjectName: React.Dispatch<React.SetStateAction<string>>;
  selectedProjectId: string | null;
  setSelectedProjectId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const TechScoutingContext = createContext<TechScoutingContextType | undefined>(undefined);
export const StakeholderIdentificationContext = createContext<StakeholderIdentificationContextType | undefined>(undefined);
export const ProjectContext = React.createContext<ProjectContextType | null>(null);

// **Custom Hook for ProjectContext**
export const useProjectContext = (): ProjectContextType => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjectContext must be used within a ProjectContext.Provider');
  }
  return context;
};
