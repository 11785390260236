// TechScouting.tsx

import React, { useState, useRef, useEffect, useContext } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/TechScouting.css';
import Loading from './loading/Loading';
import { Helmet } from 'react-helmet';
import { Document } from '../types';
import { TechScoutingContext, useProjectContext } from '../ContextStore';
import { useNavigate } from 'react-router-dom';

interface TechScoutingProps {
  isDarkMode: boolean;
}

const TechScouting: React.FC<TechScoutingProps> = ({ isDarkMode }) => {
  // Local state declarations
  const navigate = useNavigate()
  const [query, setQuery] = useState<string>('');
  const [threshold, setThreshold] = useState<number>(0.82);
  const [loading, setLoading] = useState<boolean>(false);
  const [useSBIR, setUseSBIR] = useState<boolean>(false);

  const [precisionDropdownOpen, setPrecisionDropdownOpen] = useState<boolean>(false);
  const [selectedPrecision, setSelectedPrecision] = useState<string>('Precision');

  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null);
  const [selectedDocumentTitle, setSelectedDocumentTitle] = useState<string>('Library');
  const [libraryDropdownOpen, setLibraryDropdownOpen] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [errorKey, setErrorKey] = useState<number>(0);
  const [s3Url, setS3Url] = useState<string | null>(null);
  const [retryAttempt, setRetryAttempt] = useState<boolean>(false);

  // Refs for accessibility and SSE
  const dropdownRef = useRef<HTMLDivElement>(null);
  const libraryDropdownButtonRef = useRef<HTMLButtonElement>(null);
  const precisionDropdownButtonRef = useRef<HTMLButtonElement>(null);
  const precisionMenuRef = useRef<HTMLDivElement>(null);
  const libraryDropdownMenuRef = useRef<HTMLDivElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);
  const eventSourceRef = useRef<EventSource | null>(null);

  // Cancellation flag ref. When true, we’ll cancel any streaming request.
  const cancelRequestedRef = useRef<boolean>(false);

  // API URLs
  const techURL = process.env.REACT_APP_TECH_SCOUTING_API_URL;
  const documentsURL = process.env.REACT_APP_DOCUMENTS_API_URL;
  if (!techURL || !documentsURL) {
    throw new Error('API URLs are not defined');
  }

  // Context from TechScoutingContext and default project context
  const techScoutingContext = useContext(TechScoutingContext);
  if (!techScoutingContext) {
    throw new Error('TechScoutingContext not found');
  }
  const { 
    techResponse, 
    setTechResponse, 
    setSelectedCompany, 
    setSelectedCompanyOrigin,
    skipFirstPage, // a number: 0 or 1, or undefined
    setSkipFirstPage,
  } = techScoutingContext;

  const { selectedProjectId } = useProjectContext();

  // Only clear the previous results if we're doing a fresh load (i.e. skipFirstPage is 0 or undefined)
  const maybeClearContext = () => {
    if (skipFirstPage === 0 || skipFirstPage === undefined) {
      setTechResponse(null);
    }
  };

  // Auto-submit effect: if skipFirstPage is 0 or 1 and query exists, auto-submit.
  useEffect(() => {
    if ((skipFirstPage === 0 || skipFirstPage === 1) && query.trim()) {
      autoSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipFirstPage, query]);

  // Helper to "auto-submit" (without a real event)
  const autoSubmit = () => {
    // Reset the cancellation flag on every new submission.
    cancelRequestedRef.current = false;
    const mockEvent = { preventDefault: () => {} } as React.FormEvent<HTMLFormElement>;
    handleSubmit(mockEvent);
  };

  // Sync local query with techResponse.query from context
  useEffect(() => {
    if (techResponse && typeof techResponse.query === 'string') {
      setQuery(techResponse.query);
    }
  }, [techResponse]);

  // Fetch documents for library dropdown
  useEffect(() => {
    const fetchDocs = async () => {
      try {
        const response = await axiosRequest(documentsURL, 'get');
        const sortedDocs = response.data.sort(
          (a: Document, b: Document) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        setDocuments(sortedDocs);
      } catch (err: any) {
        setError('Error fetching documents');
      }
    };
    fetchDocs();
  }, [documentsURL]);

  // handleSubmit: called by form submit or auto-submit; event is optional.
  const handleSubmit = async (
    e?: React.FormEvent<HTMLFormElement>,
    generateReport: boolean = false
  ) => {
    if (e) e.preventDefault();
    // Reset cancellation flag for this submission.
    cancelRequestedRef.current = false;
    
    setError(null);
    setS3Url(null);
    setRetryAttempt(false);

    // If we are in normal user flow (skipFirstPage undefined) and no query/doc provided, show error.
    if (skipFirstPage === undefined && !query.trim() && !selectedDocumentId) {
      setErrorKey(prev => prev + 1);
      setError('Please input a query or select a document.');
      return;
    }

    setLoading(true);
    maybeClearContext();

    try {
      // Determine the starting page: if skipFirstPage is defined use it; otherwise default to 0.
      const pageToStart = skipFirstPage !== undefined ? skipFirstPage : 0;

      const requestData = {
        query,
        threshold,
        generateReport,
        documentId: selectedDocumentId,
        useSBIR,
        projectId: selectedProjectId,
        start_page: pageToStart,
      };
      console.log('SammyStartPage', pageToStart);

      if (!useSBIR && !generateReport) {
        // Streaming scenario
        await initiateStreaming(requestData);
      } else {
        // For SBIR or report generation, use a normal POST request.
        const res = await axiosRequest(techURL, 'post', requestData);
        setLoading(false);
        // Reset skipFirstPage so that future searches do not auto-load.
        setSkipFirstPage(undefined);

        if (generateReport && res.data.s3_url) {
          setS3Url(res.data.s3_url);
        } else {
          const sanitized = sanitizeResponse(res.data);
          sanitized.query = query;
          setTechResponse(sanitized);
        }
      }
    } catch (err: any) {
      setLoading(false);
      setErrorKey(prev => prev + 1);
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else if (err.response && err.response.status === 401) {
        setError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setError('Error fetching data. Please try again.');
      }
      console.error('Error fetching data:', err, 'url used:', techURL);
    }
  };

  // Streaming: initiate SSE and check for cancellation.
  const initiateStreaming = async (data: any, retry: boolean = false) => {
    try {
      const postRes = await axiosRequest(techURL, 'post', data);
      const { temp_token, query: updatedQuery } = postRes.data;
      
      // Check cancellation before setting up the SSE.
      if (cancelRequestedRef.current) {
        console.log('Cancellation requested; aborting streaming initiation.');
        setLoading(false);
        return;
      }

      // Reset skipFirstPage for future searches.
      setSkipFirstPage(undefined);

      const finalQuery = updatedQuery || query;
      setTechResponse((prev: any) => ({
        ...prev,
        query: finalQuery,
      }));

      // Build the EventSource URL including the token.
      const eventSourceUrl = `${techURL}?query=${encodeURIComponent(finalQuery)}&temp_token=${encodeURIComponent(temp_token)}`;
      const es = new EventSource(eventSourceUrl);

      // Check again: if cancellation was requested in the meantime, close the connection.
      if (cancelRequestedRef.current) {
        es.close();
        setLoading(false);
        return;
      }

      eventSourceRef.current = es;

      es.onopen = () => {
        console.log('Connection to TechScouting SSE opened.');
      };

      es.onmessage = (evt) => {
        const newMsg = JSON.parse(evt.data);
        console.log('Received SSE chunk:', newMsg);
        if (newMsg.results) {
          handleSSEChunk(newMsg);
        }
      };

      es.onerror = (evt) => {
        console.error('TechScouting SSE error:', evt);
        es.close();
        eventSourceRef.current = null;
        setLoading(false);
      };

      es.addEventListener('end', () => {
        console.log('TechScouting SSE ended.');
        es.close();
        eventSourceRef.current = null;
        setLoading(false);
      });
    } catch (error: any) {
      console.error('Error in initiateStreaming:', error);
      if (!retryAttempt && !retry) {
        setRetryAttempt(true);
        console.warn('Retrying request after error...');
        await initiateStreaming(data, true);
      } else {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.error) {
          setError(error.response.data.error);
        } else if (error.response && error.response.status === 401) {
          setError('Unauthorized. Please log in.');
        } else {
          setError('An error occurred while initiating the request.');
        }
      }
    }
  };

  // SSE chunk handler: merge new results into context.
  const handleSSEChunk = (chunk: any) => {
    setTechResponse((prev: any) => {
      const newResults = prev?.results
        ? [...prev.results, ...sanitizeResults(chunk.results)]
        : sanitizeResults(chunk.results);
      return {
        ...prev,
        ...chunk,
        results: newResults,
        filtered_result_count: newResults.length,
      };
    });
  };

  // Utility: sanitize the backend response.
  const sanitizeResponse = (data: any) => {
    return {
      ...data,
      results: sanitizeResults(data.results),
    };
  };

  const sanitizeResults = (arr: any[]) => {
    if (!arr) return [];
    return arr.map((item) => ({
      ...item,
      company: item.company?.replace(/[|]/g, '') ?? '',
      abstract: item.abstract?.replace(/[|]/g, '') ?? '',
    }));
  };

  // Handle report generation.
  const handleGenerateReport = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    await handleSubmit(undefined, true);
  };

  // Handle threshold changes.
  const handleThresholdChange = (val: string) => {
    switch (val) {
      case 'Specific':
        setThreshold(0.85);
        setSelectedPrecision('Specific');
        break;
      case 'General':
        setThreshold(0.82);
        setSelectedPrecision('General');
        break;
      case 'Broad':
        setThreshold(0.79);
        setSelectedPrecision('Broad');
        break;
      default:
        setThreshold(0.82);
        setSelectedPrecision('Precision');
    }
    setPrecisionDropdownOpen(false);
    libraryDropdownButtonRef.current?.focus();
  };

  // Dropdown open/close handlers.
  const handleDropdownButtonClick = () => {
    setPrecisionDropdownOpen(!precisionDropdownOpen);
    setLibraryDropdownOpen(false);
  };

  const handleLibraryDropdownClick = () => {
    setLibraryDropdownOpen(!libraryDropdownOpen);
    setPrecisionDropdownOpen(false);
  };

  // When user selects a library document.
  const handleLibrarySelection = (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    doc: Document | null
  ) => {
    e.preventDefault();
    if ('stopPropagation' in e) e.stopPropagation();

    if (doc) {
      setSelectedDocumentId(doc.id);
      setSelectedDocumentTitle(doc.documentTitle);
    } else {
      setSelectedDocumentId(null);
      setSelectedDocumentTitle('Library');
    }
    setLibraryDropdownOpen(false);
    setTimeout(() => {
      submitButtonRef.current?.focus();
    }, 0);
  };

  // Download handler.
  const handleDownload = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'TechScouting_Report.docx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Toggle SBIR/Startup slider.
  const toggleSlider = () => {
    setUseSBIR(prev => !prev);
  };

  // Close dropdowns if clicking outside.
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        precisionDropdownOpen &&
        precisionMenuRef.current &&
        !precisionMenuRef.current.contains(event.target as Node) &&
        !precisionDropdownButtonRef.current?.contains(event.target as Node)
      ) {
        setPrecisionDropdownOpen(false);
      }
      if (
        libraryDropdownOpen &&
        libraryDropdownMenuRef.current &&
        !libraryDropdownMenuRef.current.contains(event.target as Node) &&
        !libraryDropdownButtonRef.current?.contains(event.target as Node)
      ) {
        setLibraryDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [precisionDropdownOpen, libraryDropdownOpen]);

  // Focus error messages.
  useEffect(() => {
    if (error && errorRef.current) {
      errorRef.current.focus();
    }
  }, [error]);

  // Keyboard navigation in dropdowns.
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Precision dropdown
      if (precisionDropdownOpen && precisionMenuRef.current) {
        const items = precisionMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex(item => item === document.activeElement);
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            (items[(activeIndex + 1) % items.length] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            (items[(activeIndex - 1 + items.length) % items.length] as HTMLElement).focus();
            break;
          case 'Enter':
            event.preventDefault();
            if (activeIndex !== -1) {
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Escape':
            setPrecisionDropdownOpen(false);
            precisionDropdownButtonRef.current?.focus();
            break;
          case 'Tab':
            if (!event.shiftKey && activeIndex === items.length - 1) {
              event.preventDefault();
              setPrecisionDropdownOpen(false);
              libraryDropdownButtonRef.current?.focus();
            } else if (event.shiftKey && activeIndex === 0) {
              event.preventDefault();
              setPrecisionDropdownOpen(false);
              precisionDropdownButtonRef.current?.focus();
            }
            break;
        }
      }
      // Library dropdown
      if (libraryDropdownOpen && libraryDropdownMenuRef.current) {
        const items = libraryDropdownMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex(item => item === document.activeElement);
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            (items[(activeIndex + 1) % items.length] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            (items[(activeIndex - 1 + items.length) % items.length] as HTMLElement).focus();
            break;
          case 'Enter':
            event.preventDefault();
            if (activeIndex !== -1) {
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Escape':
            setLibraryDropdownOpen(false);
            libraryDropdownButtonRef.current?.focus();
            break;
          case 'Tab':
            if (!event.shiftKey && activeIndex === items.length - 1) {
              event.preventDefault();
              setLibraryDropdownOpen(false);
              submitButtonRef.current?.focus();
            } else if (event.shiftKey && activeIndex === 0) {
              event.preventDefault();
              setLibraryDropdownOpen(false);
              libraryDropdownButtonRef.current?.focus();
            }
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [precisionDropdownOpen, libraryDropdownOpen]);

  // When a dropdown opens, focus the first item.
  useEffect(() => {
    if (precisionDropdownOpen && precisionMenuRef.current) {
      const firstItem = precisionMenuRef.current.querySelector('div[role="menuitem"]');
      if (firstItem) (firstItem as HTMLElement).focus();
    }
    if (libraryDropdownOpen && libraryDropdownMenuRef.current) {
      const firstItem = libraryDropdownMenuRef.current.querySelector('div[role="menuitem"]');
      if (firstItem) (firstItem as HTMLElement).focus();
    }
  }, [precisionDropdownOpen, libraryDropdownOpen]);

  // Cleanup: close the EventSource when component unmounts.
  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
    };
  }, []);

  // Render the component.
  return (
    <>
      <Helmet>
        <title>Technology Scouting</title>
      </Helmet>
      <nav aria-label="Primary Navigation" className="primary-nav"></nav>
      <main role="main" tabIndex={-1} className="main-content">
        <div className={`tech-scouting-container ${isDarkMode ? 'dark' : 'light'}`}>
          <h1 tabIndex={0} role="heading" aria-level={1} className={isDarkMode ? 'dark' : 'light'}>
            Technology Scouting
          </h1>
          <p tabIndex={0} role="note" className={isDarkMode ? 'dark' : 'light'}>
            Performs market research to find sole-source eligible companies for an inputted technology or selected document.
          </p>

          <form className="tech-scouting-form" onSubmit={(e) => handleSubmit(e)}>
            <div className="tech-scouting-input-container" ref={dropdownRef}>
              <label htmlFor="tech-scouting-query" className="visually-hidden">
                Search Query
              </label>
              <input
                id="tech-scouting-query"
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Ex: Navigation in GPS-denied Environments"
                autoComplete="on"
                aria-describedby="error-message"
                className={`${isDarkMode ? 'dark' : 'light'}`}
                disabled={loading}
              />

              <div className="tech-scouting-buttons-container">
                {/* Precision Dropdown */}
                <div className="tech-scouting-button-with-tooltip">
                  <button
                    type="button"
                    className={`tech-scouting-dropdown-button ${isDarkMode ? 'dark' : 'light'}`}
                    onClick={handleDropdownButtonClick}
                    aria-expanded={precisionDropdownOpen}
                    aria-controls="tech-scouting-precision-dropdown-menu"
                    ref={precisionDropdownButtonRef}
                    disabled={loading}
                  >
                    &#9776; {selectedPrecision}
                  </button>
                  <span className="tech-scouting-tooltip-text">
                    Limits number of results based on relevance
                  </span>
                </div>

                {/* Library Dropdown */}
                <div className="tech-scouting-button-with-tooltip">
                  <button
                    type="button"
                    className={`tech-scouting-library-dropdown-button ${isDarkMode ? 'dark' : 'light'}`}
                    onClick={handleLibraryDropdownClick}
                    aria-expanded={libraryDropdownOpen}
                    aria-controls="tech-scouting-library-dropdown-menu"
                    ref={libraryDropdownButtonRef}
                    disabled={loading}
                  >
                    &#128194; {selectedDocumentTitle}
                  </button>
                  <span className="tech-scouting-tooltip-text">
                    Select a document from your library
                  </span>
                </div>

                {/* SBIR Toggle */}
                <div className="tech-scouting-slider-container">
                  <label className="tech-scouting-switch">
                    <span className="visually-hidden">SBIR Toggle</span>
                    <input
                      type="checkbox"
                      checked={useSBIR}
                      onChange={toggleSlider}
                      disabled={loading}
                    />
                    <span className="tech-scouting-slider tech-scouting-round"></span>
                  </label>
                  <span className="tech-scouting-slider-label">
                    {useSBIR ? 'SBIR' : 'Startup'}
                  </span>
                </div>

                {/* Submit/Cancel Button */}
                <button
                  type={loading ? 'button' : 'submit'}
                  className={`tech-scouting-submit-button ${isDarkMode ? 'dark' : 'light'}`}
                  aria-label={loading ? 'Cancel' : 'Submit Query'}
                  ref={submitButtonRef}
                  onClick={(e) => {
                    if (loading) {
                      e.preventDefault();
                      // When cancel is clicked, set the cancellation flag AND reset skipFirstPage
                      cancelRequestedRef.current = true;
                      if (eventSourceRef.current) {
                        console.log('Cancel clicked, closing SSE.');
                        eventSourceRef.current.close();
                        eventSourceRef.current = null;
                      }
                      // Reset the flag so auto-submit doesn't trigger
                      setSkipFirstPage(undefined);
                      setLoading(false);
                    }
                  }}
                >
                  {loading ? '✕' : '➤'}
                </button>
              </div>

              {/* Precision Dropdown Menu */}
              <div
                className={`tech-scouting-dropdown-menu ${isDarkMode ? 'dark' : 'light'} ${precisionDropdownOpen ? 'open' : 'closed'}`}
                id="tech-scouting-precision-dropdown-menu"
                role="menu"
                ref={precisionMenuRef}
                aria-hidden={!precisionDropdownOpen}
              >
                <div
                  tabIndex={0}
                  role="menuitem"
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onClick={() => handleThresholdChange('Specific')}
                  onKeyDown={(event) => { if (event.key === 'Enter') handleThresholdChange('Specific'); }}
                >
                  Specific
                </div>
                <div
                  tabIndex={0}
                  role="menuitem"
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onClick={() => handleThresholdChange('General')}
                  onKeyDown={(event) => { if (event.key === 'Enter') handleThresholdChange('General'); }}
                >
                  General
                </div>
                <div
                  tabIndex={0}
                  role="menuitem"
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onClick={() => handleThresholdChange('Broad')}
                  onKeyDown={(event) => { if (event.key === 'Enter') handleThresholdChange('Broad'); }}
                >
                  Broad
                </div>
              </div>

              {/* Library Dropdown Menu */}
              <div
                className={`tech-scouting-library-dropdown-menu ${isDarkMode ? 'dark' : 'light'} ${libraryDropdownOpen ? 'open' : 'closed'}`}
                id="tech-scouting-library-dropdown-menu"
                role="menu"
                ref={libraryDropdownMenuRef}
                aria-hidden={!libraryDropdownOpen}
              >
                <div
                  role="menuitem"
                  tabIndex={0}
                  onClick={(evt) => handleLibrarySelection(evt, null)}
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onKeyDown={(event) => { if (event.key === 'Enter') handleLibrarySelection(event, null); }}
                >
                  -- No Document --
                </div>
                {documents.map((doc) => (
                  <div
                    key={doc.id}
                    role="menuitem"
                    tabIndex={0}
                    onClick={(evt) => handleLibrarySelection(evt, doc)}
                    className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                    onKeyDown={(event) => { if (event.key === 'Enter') handleLibrarySelection(event, doc); }}
                  >
                    {doc.documentTitle}
                  </div>
                ))}
              </div>
            </div>

            {/* Error Message */}
            <div
              id="error-message"
              key={errorKey}
              className={`tech-scouting-error ${error ? 'visible' : 'hidden'} ${isDarkMode ? 'dark' : 'light'}`}
              aria-live="assertive"
              role="alert"
              ref={errorRef}
              tabIndex={-1}
            >
              {error}
            </div>
          </form>

          {/* Loading Spinner */}
          {loading && <Loading />}

          {/* Results Display */}
          {techResponse && techResponse.results && techResponse.results.length > 0 && (
            <div className={`tech-scouting-response ${isDarkMode ? 'dark' : 'light'}`}>
              {s3Url && (
                <div className="tech-scouting-report-generated-header">
                  <h2 className={`tech-scouting-report-generated-header ${isDarkMode ? 'dark' : 'light'}`} tabIndex={0} role="heading" aria-level={2}>
                    Report Generated!
                  </h2>
                  <div className="tech-scouting-report-generated-button">
                    <button onClick={() => handleDownload(s3Url)} className={`tech-scouting-download-button ${isDarkMode ? 'dark' : 'light'}`}>
                      Download Report
                    </button>
                  </div>
                </div>
              )}
              <div className="tech-scouting-results-header">
                <h2 tabIndex={0} role="heading" aria-level={2} className={isDarkMode ? 'dark' : 'light'}>
                  Results: {techResponse.results.length}
                </h2>
                <button className={`tech-scouting-generate-report-button ${isDarkMode ? 'dark' : 'light'}`} onClick={handleGenerateReport}>
                  Generate Report
                </button>
              </div>
              <div className="tech-scouting-results-container">
                {techResponse.results.map((r: any, idx: number) => (
                  <div key={idx} className={`tech-scouting-result-card ${isDarkMode ? 'dark' : 'light'}`}>
                    <h3 id={`result-heading-${idx}`} tabIndex={0} role="heading" aria-level={3} className={isDarkMode ? 'dark' : 'light'}>
                      <button
                        onClick={() => {
                          setSelectedCompany(r);
                          setSelectedCompanyOrigin('TechScouting');
                          navigate('/company-details')
                        }}
                        className={`company-button ${isDarkMode ? 'dark' : 'light'}`}
                      >
                        {idx + 1}: {r.company}
                      </button>
                    </h3>
                    <p tabIndex={0} role="document" className={isDarkMode ? 'dark' : 'light'}>
                      {r.abstract}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default TechScouting;
