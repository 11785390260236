import React from 'react';
import './style/SiteMap.css';
import { SectionState } from '../types';
import { useNavigate } from 'react-router-dom';
interface SiteMapProps {
  isInProposalsGroup: boolean;
  isDarkMode: boolean;
  setSelectedLibraryProjectId: React.Dispatch<React.SetStateAction<string | null>>;
}

const SiteMap: React.FC<SiteMapProps> = ({ isInProposalsGroup, isDarkMode, setSelectedLibraryProjectId }) => {
  const navigate = useNavigate();
  return (
    <div className={`site-map ${isDarkMode ? 'dark' : 'light'}`}>
      <ul>
        {isInProposalsGroup ? (
          <>
            <li>
              <button onClick={() => navigate('/proposals')}>Proposal Evaluator</button>
            </li>
            <li>
              <button onClick={() => {
                setSelectedLibraryProjectId(null);        // <-- reset it here!
                navigate('/library');
              }}>Library</button>
            </li>
          </>
        ) : (
          <>
            <li>
              <button onClick={() => navigate('/techscouting')}>Tech Scouting</button>
            </li>
            <li>
              <button onClick={() => navigate('/stakeholders')}>Stakeholder Identification</button>
            </li>
            <li>
              <button onClick={() => navigate('project-support')}>Project Support</button>
            </li>
            <li>
              <button onClick={() => navigate('/mou')}>MOU Generation</button>
            </li>
            <li>
              <button onClick={() => {
                setSelectedLibraryProjectId(null);        // <-- reset it here!
                navigate('/library');
              }}>Library</button>
            </li>
            <li>
              <button onClick={() => navigate('/atoassistant')}>ATO Assistant</button>
            </li>
            <li>
              <button onClick={() => navigate('/proposals')}>Proposal Evaluator</button>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default SiteMap;
