// CompanyDetails.tsx

import React, { useContext } from 'react';
import { TechScoutingContext } from '../ContextStore';
import './style/CompanyDetails.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

interface CompanyDetailsProps {
  isDarkMode: boolean;
}

const CompanyDetails: React.FC<CompanyDetailsProps> = ({ isDarkMode }) => {
  const navigate = useNavigate();
  const techScoutingContext = useContext(TechScoutingContext);
  if (!techScoutingContext) {
    throw new Error('TechScoutingContext not found');
  }
  const { selectedCompany, setSelectedCompany, selectedCompanyOrigin } = techScoutingContext;

  if (!selectedCompany) {
    return (
      <div className={`company-details-container ${isDarkMode ? 'dark' : 'light'}`}>
        <h1>Company Details Not Available</h1>
        <button
          onClick={() => navigate('/techscouting')}
          className={`company-details-back-button ${isDarkMode ? 'dark' : 'light'}`}
        >
          Go Back
        </button>
      </div>
    );
  }

  // Destructure the selectedCompany for easier access
  const {
    company,
    abstract,
    location,
    funding_total,
    founding_date,
    contact_email,
    contact_phone,
    url,
  } = selectedCompany;

  // Function to format the founding date based on granularity
  const formatFoundingDate = (dateString: string, granularity: string): string => {
    const date = new Date(dateString);
    switch (granularity) {
      case 'YEAR':
        return date.getFullYear().toString();
      case 'MONTH':
        return `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;
      case 'DAY':
      default:
        return date.toLocaleDateString();
    }
  };

  // Helper function to format contact info
  const formatContactInfo = (info: string | string[] | null | undefined): string => {
    if (!info) return 'No Data';
    if (Array.isArray(info)) return info.length > 0 ? info.join(', ') : 'No Data';
    if (typeof info === 'string') return info.trim() !== '' ? info : 'No Data';
    return 'No Data';
  };

  // Handle Go Back button click
  const handleGoBack = () => {
    setSelectedCompany(null); // Clear the selected company
    if (selectedCompanyOrigin === 'ProjectDashboard') {
      navigate('/dashboard');
    } else {
      // Default to TechScouting
      navigate('/techscouting');
    }
  };

  return (
    <div className={`company-details-container ${isDarkMode ? 'dark' : 'light'}`}>
      <nav aria-label="Primary Navigation" className="primary-nav"></nav>
      <main role="main" tabIndex={-1} className="main-content">
      <h1>{company}</h1>
      <p>
        <strong>Description:</strong> {abstract}
      </p>
      <p>
        <strong>Location:</strong> {location}
      </p>
      <p>
        <strong>Funding Total:</strong>{' '}
        {funding_total !== null && funding_total !== undefined
          ? `$${funding_total.toLocaleString()}`
          : 'No Data'}
      </p>
      <p>
        <strong>Founding Date:</strong>{' '}
        {founding_date && founding_date.date
          ? formatFoundingDate(founding_date.date, founding_date.granularity)
          : 'No Data'}
      </p>
      <p>
        <strong>Contact Email:</strong> {formatContactInfo(contact_email)}
      </p>
      <p>
        <strong>Contact Phone:</strong> {formatContactInfo(contact_phone)}
      </p>
      <p>
        <strong>Website:</strong>{' '}
        {url ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        ) : (
          'No Data'
        )}
      </p>
      <button
        onClick={handleGoBack} // Use the new handler
        className={`company-details-back-button ${isDarkMode ? 'dark' : 'light'}`}
      >
        Go Back
      </button>
      </main>
    </div>
  );
};

export default CompanyDetails;
