// App.tsx

import React, { useState, useEffect, useRef } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  Outlet
} from 'react-router-dom';
import Navbar from './components/Navbar';
import TechScouting from './components/TechScouting';
import StakeholderIdentification from './components/StakeholderIdentification';
import ProjectSupport from './components/ProjectSupport';
import SignIn from './components/SignIn';
import MOUGeneration from './components/MOUGeneration';
import SiteMap from './components/SiteMap';
import Library from './components/Library';
import ATODocuments from './components/ATODocuments';
import ATOAssistant from './components/ATOAssistant';
import SignUp from './components/SignUp';
import Settings from './components/Settings';
import CompanyDetails from './components/CompanyDetails';
import Proposals from './components/Proposals';
import NewProject from './components/NewProject';
import ProjectDashboard from './components/ProjectDashboard';
import {
  TechScoutingContext,
  StakeholderIdentificationContext,
  ProjectContext,
} from './ContextStore';
import { StakeholderData, CompanyData, Project, ProjectData } from './types';
import { createPusherInstance } from './utils/pusherConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pusher, { Channel } from 'pusher-js';
import axiosRequest from './utils/axiosRequest';
import './App.css';

/**
 * This component protects routes that require authentication.
 * If the user is not authenticated, it redirects them to the /signin route.
 */
interface RequireAuthProps {
  isAuthenticated: boolean;
  children?: JSX.Element;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ isAuthenticated, children }) => {
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to="/signin" replace state={{ from: location }} />;
  }
  return children ? children : <Outlet />;
};

const App: React.FC = () => {
  // Global authentication and user state
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isInProposalsGroup, setIsInProposalsGroup] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const [userId, setUserId] = useState<string>('');

  // TechScouting context state
  const [techResponse, setTechResponse] = useState<any>(null);
  const [selectedCompany, setSelectedCompany] = useState<CompanyData | null>(null);
  const [stakeholderResponse, setStakeholderResponse] = useState<StakeholderData[]>([]);
  const [selectedCompanyOrigin, setSelectedCompanyOrigin] = useState<string | null>(null);
  const [skipFirstPage, setSkipFirstPage] = useState<number | undefined>(undefined);

  // Pusher refs
  const pusherRef = useRef<Pusher | null>(null);
  const channelRef = useRef<Channel | null>(null);

  // Project state
  const [defaultProjectName, setDefaultProjectName] = useState<string>('');
  const [defaultProjectId, setDefaultProjectId] = useState<string>('');
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [selectedLibraryProjectId, setSelectedLibraryProjectId] = useState<string | null>(null);

  // Dark mode state
  const [isDarkMode, setIsDarkMode] = useState<boolean>(
    () => localStorage.getItem('darkMode') !== 'false'
  );

  const authURL = process.env.REACT_APP_SIGNIN_API_URL;
  if (!authURL) {
    throw new Error('REACT_APP_SIGNIN_API_URL is not defined');
  }
  const projectsURL = process.env.REACT_APP_PROJECTS_API_URL;
  if (!projectsURL) {
    throw new Error('REACT_APP_PROJECTS_API_URL is not defined');
  }

  const [projects, setProjects] = useState<Project[]>([]);

  // For navigation, use the useNavigate hook.
  const navigate = useNavigate();
  const location = useLocation();

  // Define routes for which the Navbar and SiteMap should be hidden.
  const hideUIRoutes = ['/signin', '/signup'];
  const showUI = !hideUIRoutes.includes(location.pathname);

  useEffect(() => {
    const theme = isDarkMode ? 'dark-mode' : 'light-mode';
    document.body.classList.add(theme);
    return () => {
      document.body.classList.remove('dark-mode', 'light-mode');
    };
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    localStorage.setItem('darkMode', newMode ? 'true' : 'false');
    document.body.classList.toggle('dark-mode', newMode);
    document.body.classList.toggle('light-mode', !newMode);
  };

  // Pusher initialization
  useEffect(() => {
    if (userId && isAuthenticated) {
      initializePusher();
    }
    return () => {
      cleanupPusher();
    };
  }, [userId, isAuthenticated]);

  const initializePusher = () => {
    if (pusherRef.current) {
      console.log('Pusher already initialized.');
      return;
    }
    if (!userId) {
      console.error('User ID not found for Pusher.');
      return;
    }
    const pusher = createPusherInstance();
    pusherRef.current = pusher;
    console.log(`Subscribing to private channel for user ${userId}`);
    const channel = pusher.subscribe(`private-user-${userId}`);
    channelRef.current = channel;
    channel.bind('document-event', handleDocumentEvent);
    channel.bind('pusher:subscription_error', handleSubscriptionError);
    console.log('Pusher subscription initialized.');
  };

  const handleDocumentEvent = (data: any) => {
    console.log('Received document event:', data);
    if (data.message === 'document_complete') {
      const { document_url } = data;
      toast.success('Generated Document added to Library!', {
        position: 'top-right',
        autoClose: 5000,
        theme: 'dark',
        icon: (
          <img src="/LogoThumbnailWhite.png" alt="Logo Thumbnail" className="toast-custom-icon" />
        ),
        onClick: () => window.open(document_url, '_blank'),
        className: 'custom-toast-clickable',
      });
    }
  };

  const handleSubscriptionError = (status: number) => {
    console.error('Pusher subscription error:', status);
  };

  const cleanupPusher = () => {
    if (channelRef.current) {
      console.log('Cleaning up Pusher channel subscription');
      channelRef.current.unbind_all();
      channelRef.current.unsubscribe();
      channelRef.current = null;
    }
    if (pusherRef.current) {
      console.log('Disconnecting Pusher instance');
      pusherRef.current.disconnect();
      pusherRef.current = null;
    }
  };

  // Sign in handler: sets authentication and initial project state.
  const handleSignIn = (email: string, uid: string, groups: string[], userProjects: Project[]) => {
    setIsAuthenticated(true);
    setUserEmail(email);
    setUserId(uid);
    setIsInProposalsGroup(Array.isArray(groups) && groups.includes('proposals'));
    setProjects(userProjects);
    if (userProjects && userProjects.length > 0) {
      const defaultProj = userProjects.find((proj: Project) => proj.is_default);
      if (defaultProj) {
        setDefaultProjectName(defaultProj.name);
        setDefaultProjectId(defaultProj.id);
        setSelectedProjectId(defaultProj.id);
      }
      // Navigate to dashboard route
      navigate('/dashboard');
    } else {
      navigate('/new-project');
    }
  };

  const handleSignOut = async () => {
    cleanupPusher();
    try {
      await axiosRequest(`${authURL}signout/`, 'post');
    } catch (error) {
      console.error('Error during sign out:', error);
    }
    setIsAuthenticated(false);
    navigate('/signin');
    setSelectedCompany(null);
    setUserEmail('');
    setUserId('');
    setIsInProposalsGroup(false);
    setDefaultProjectName('');
    setDefaultProjectId('');
    setSelectedProjectId(null);
    setProjects([]);
  };

  // Define the removeProjectFromList callback.
  const removeProjectFromList = (projectId: string) => {
    setProjects((prevProjects) => prevProjects.filter((proj) => proj.id !== projectId));
    if (selectedProjectId === projectId) {
      setSelectedProjectId(null);
      navigate('/techscouting');
      toast.info('Project has been deleted and you have been redirected.', {
        position: 'top-right',
        autoClose: 5000,
        theme: 'dark',
      });
    }
  };

  return (
    <div className="App">
      <ProjectContext.Provider
        value={{
          defaultProjectId,
          setDefaultProjectId,
          defaultProjectName,
          setDefaultProjectName,
          selectedProjectId,
          setSelectedProjectId,
        }}
      >
        <TechScoutingContext.Provider
          value={{
            techResponse,
            setTechResponse,
            selectedCompany,
            setSelectedCompany,
            selectedCompanyOrigin,
            setSelectedCompanyOrigin,
            skipFirstPage,
            setSkipFirstPage,
          }}
        >
          <StakeholderIdentificationContext.Provider
            value={{ stakeholderResponse, setStakeholderResponse }}
          >
            {/* Conditionally render the Navbar */}
            {showUI && (
              <Navbar
                userEmail={userEmail}
                isDarkMode={isDarkMode}
                projects={projects}
                setCurrentProjectId={setSelectedProjectId}
                selectedProjectId={selectedProjectId}
              />
            )}
            <div className={`content ${!showUI ? 'full-screen' : ''}`} id="main-content" tabIndex={-1}>
              <Routes>
                {/* Public Routes */}
                <Route path="/signin" element={<SignIn onSignIn={handleSignIn} />} />
                <Route path="/signup" element={<SignUp onSignIn={handleSignIn} />} />

                {/* Protected Routes */}
                <Route element={<RequireAuth isAuthenticated={isAuthenticated} />}>
                  <Route
                    path="/dashboard"
                    element={
                      <ProjectDashboard
                        isDarkMode={isDarkMode}
                        projectId={selectedProjectId}
                        setSelectedProjectId={setSelectedProjectId}
                        setSelectedLibraryProjectId={setSelectedLibraryProjectId}
                        updateProjectInList={(updatedProject: ProjectData) => {
                          setProjects((prevProjects) =>
                            prevProjects.map((proj) =>
                              proj.id === updatedProject.id ? { ...proj, name: updatedProject.name } : proj
                            )
                          );
                        }}
                        removeProjectFromList={removeProjectFromList}
                      />
                    }
                  />
                  <Route path="/techscouting" element={<TechScouting isDarkMode={isDarkMode} />} />
                  <Route path="/stakeholders" element={<StakeholderIdentification isDarkMode={isDarkMode} />} />
                  <Route path="/company-details" element={<CompanyDetails isDarkMode={isDarkMode} />} />
                  <Route path="/project-support" element={<ProjectSupport isDarkMode={isDarkMode} />} />
                  <Route path="/atoassistant" element={<ATOAssistant isDarkMode={isDarkMode} />} />
                  <Route path="/mou" element={<MOUGeneration isDarkMode={isDarkMode} />} />
                  <Route
                    path="/library"
                    element={
                      <Library
                        isDarkMode={isDarkMode}
                        isInProposalsGroup={isInProposalsGroup}
                        selectedLibraryProjectId={selectedLibraryProjectId}
                        setSelectedLibraryProjectId={setSelectedLibraryProjectId}
                      />
                    }
                  />
                  <Route path="/atodocuments" element={<ATODocuments isDarkMode={isDarkMode} />} />
                  <Route
                    path="/settings"
                    element={
                      <Settings
                        userEmail={userEmail}
                        handleSignOut={handleSignOut}
                        isDarkMode={isDarkMode}
                        toggleDarkMode={toggleDarkMode}
                      />
                    }
                  />
                  <Route
                    path="/new-project"
                    element={
                      <NewProject
                        setSelectedProjectId={setSelectedProjectId}
                        isDarkMode={isDarkMode}
                        fetchProjects={async () => {
                          try {
                            const response = await axiosRequest(`${projectsURL}`, 'get');
                            const sortedProjects: Project[] = response.data.sort(
                              (a: Project, b: Project) =>
                                new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                            );
                            setProjects(sortedProjects);
                            const defaultProj = sortedProjects.find((proj: Project) => proj.is_default);
                            if (defaultProj) {
                              setDefaultProjectName(defaultProj.name);
                              setDefaultProjectId(defaultProj.id);
                              setSelectedProjectId(defaultProj.id);
                            }
                          } catch (error) {
                            console.error('Error fetching projects after new project created:', error);
                          }
                        }}
                      />
                    }
                  />
                  {/* Fallback Route */}
                  <Route path="*" element={<TechScouting isDarkMode={isDarkMode} />} />
                </Route>
              </Routes>
            </div>
            {/* Conditionally render the SiteMap */}
            {showUI && (
              <SiteMap
                isDarkMode={isDarkMode}
                isInProposalsGroup={isInProposalsGroup}
                setSelectedLibraryProjectId={setSelectedLibraryProjectId}
              />
            )}
            <ToastContainer />
          </StakeholderIdentificationContext.Provider>
        </TechScoutingContext.Provider>
      </ProjectContext.Provider>
    </div>
  );
};

export default App;
